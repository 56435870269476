import { ReactNode } from 'react';
import { DataTableModel } from 'src/shared/ui/data/DataTableModel';

export enum DATA_TABLE_SORT_ORDER {
  ascending = 'ascending',
  descending = 'descending',
  none = 'none',
}

export interface CellRendererProps<T extends DataTableDataItem> {
  dataItem: T;
  table: DataTableModel<T>;
  index: number;
}

export interface DataTableColumnDef<T extends DataTableDataItem> {
  className?: string;
  headerOnlyClassName?: string;
  hideFromTable?: (table: DataTableModel<T>) => boolean;
  /**
   * Unique key for the column / cell.
   */
  key: string;
  /**
   * The minimum width of the data table required for the field to stay visible on the table.
   */
  overflowBreakpoint?: number;
  sortable?: {
    defaultOrder?: DATA_TABLE_SORT_ORDER;
    key: string;
  };
  title?: string | ReactNode;
  width: number;
  dataCell(props: CellRendererProps<T>): JSX.Element | JSX.Element[] | string | null;
  dialogDataCell?(props: CellRendererProps<T>): JSX.Element | null;
}

export interface DataTableDataItem extends AnyObject {
  id: number | string;
}
